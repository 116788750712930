@import "config/config";

/*
  <table class="ui-component-calendar variant:{booking, corp}">
    <caption>
      <div>
        <button>
          <i class="far fa-fw fa-arrow-left" aria-hidden="true"></i>
          <span class="sr-only">Previous month</span>
        </button>
        <span>January 2025</span>
        <button>
          <i class="far fa-fw fa-arrow-right" aria-hidden="true"></i>
          <span class="sr-only">Next month</span>
        </button>
      </div>
    </caption>
    <thead>
      <tr>
        <th><span aria-hidden="true">Mo</span><span class="sr-only">Monday</span></th>
        <th><span aria-hidden="true">Tu</span><span class="sr-only">Tuesday</span></th>
        <th><span aria-hidden="true">We</span><span class="sr-only">Wednesday</span></th>
        <th><span aria-hidden="true">Th</span><span class="sr-only">Thursday</span></th>
        <th><span aria-hidden="true">Fr</span><span class="sr-only">Friday</span></th>
        <th><span aria-hidden="true">Sa</span><span class="sr-only">Saturday</span></th>
        <th><span aria-hidden="true">Su</span><span class="sr-only">Sunday</span></th>
      </tr>
    </thead>
    <tbody>
      ...
    </tbody>
  </table>

  Note[0]: We use percentage font sizes so we can shrink/enlarge the entire calendar from the base
  element based on viewport size.
*/

.ui-component-calendar {
  --cell-gap: 5px;
  --mobile-offset: clamp(-1 * var(--space-sm), -1 * var(--space-sm) + var(--viewport-ratio), 0rem);

  table-layout: fixed;
  height: 1px; // To enable full-height children of table cells. Do not ask why.
  width: calc(100% - 2 * var(--mobile-offset));
  margin-left: var(--mobile-offset);
  border-spacing: var(--cell-gap);
  font-size: clamp(0.85rem, 0.85rem + (1 - 0.85) * var(--viewport-ratio), 1rem);

  // Table parent needs `container-type` for `@container` below
  :has(> &) {
    container-type: inline-size;
  }

  @container (width < 500px) {
    border-spacing: 0 var(--cell-gap);
  }

  caption {
    font-weight: 700;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        font-size: calc((18 / 16) * 100%); // Note[0]
      }
    }

    button {
      appearance: none;
      border: 1px solid transparent;
      border-radius: $ui-border-radius;
      background: transparent;
      padding: var(--space-xs);
      color: $sb-green;
      cursor: pointer;

      .theme\:premium & {
        color: $sb-gold;
      }

      &:focus-visible {
        outline: 1px solid $sb-green;

        .theme\:premium & {
          outline-color: $sb-gold;
        }
      }

      &[disabled] {
        opacity: 0;
        cursor: default;
      }
    }
  }

  tbody[hidden] {
    display: none;
  }

  th {
    font-weight: 400;
  }

  td {
    width: calc(100cqw / 7);

    label {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: var(--cell-gap);
      border: 1px solid transparent;
      border-radius: 16px;
      background: transparent;
      text-align: center;
      cursor: pointer;

      @container (width >= 600px) {
        aspect-ratio: 1 / 1;
      }

      input[type="radio"] {
        position: absolute;
        opacity: 0;
      }

      &:has(:focus),
      &:has(:checked) {
        border-color: $sb-green;

        .theme\:premium & {
          border-color: $sb-gold;
        }
      }

      &:has(:checked) {
        color: #fff;
        background-color: $sb-green;

        > span > * {
          color: inherit !important;
        }

        .theme\:premium & {
          background-color: $sb-gold;
        }
      }

      // Date
      > span:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        font-size: calc((14 / 16) * 100%); // Note[0]
        line-height: 1;
      }

      [aria-current] ~ span:first-of-type {
        background-color: $sb-green-100;

        .theme\:premium & {
          background-color: $sb-gold-100;
        }
      }
      // Markup value
      > div {
        position: absolute;
        top: 7px;
        left: -2px;
        padding-left: 2px;
        padding-right: 3px;
        color: $black;
        border-radius: $ui-border-radius;
        border: 1px solid grey;
        background-color: #fff;
        font-size: 80%;
        line-height: 1.7;

        @media only screen and (max-width: 600px) {
          top: 9px;
          font-size: 70%;
          line-height: 1.8;
          padding-left: 1px;
          padding-right: 1px;
        }
      }

      // Price
      > span:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        margin: auto 0;

        @container (width < 500px) {
          // £ sign
          > :first-letter {
            position: relative;
            top: -0.5em;
            font-size: 75%;
            line-height: 0;
            vertical-align: baseline;
          }
        }

        // Strike-though price
        > :first-child:not(:last-child) {
          font-size: calc((14 / 16) * 100%); // Note[0]
          color: $black;
          opacity: $ui-opacity-disabled;
          text-decoration: line-through;
        }

        > :last-child {
          color: $sb-green;

          .theme\:premium & {
            color: $sb-gold;
          }
        }
      }

      &:has([disabled]) {
        text-decoration: line-through;
        opacity: $ui-opacity-disabled;
        cursor: auto;

        > * {
          color: $black !important;
        }
      }

      &:has(.first-in-selected-range) {
        border-radius: 16px 0 0 16px;
      }
  
      &:has(.in-selected-range),
      &:has(.last-in-selected-range) {
        border-radius: 0;
        background-color: $grey-100;
        opacity: 1;
        text-decoration: none;

        // Hide price
        > span:last-child > span {
          display: none;
        }
      }

      &:has(.last-in-selected-range) {
        border: 1px solid $sb-green;
        border-radius: 0 16px 16px 0;
      }
    }
  }

  &.variant\:corp {
    td {
      position: relative;

      // Allocations
      > div:first-child {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        gap: 2px;

        > * {
          display: flex;
          align-items: center;
          padding: 1px;
          aspect-ratio: 1 / 1;
          border-radius: $ui-border-radius;
          color: #fff;
          font-size: calc((12 / 16) * 100%); // Note[0]
          line-height: 1;

          &.cache {
            background-color: color-mix(in srgb, var(--green) 80%, transparent);
          }

          &.committed {
            background-color: color-mix(in srgb, var(--yellow) 80%, transparent);
          }

          &.online {
            background-color: color-mix(in srgb, var(--blue) 80%, transparent);
          }

          &.online-plus {
            background-color: color-mix(in srgb, var(--purple) 80%, transparent);
          }

          &.protected {
            background-color: color-mix(in srgb, var(--red) 80%, transparent);
          }
        }
      }

      label {
        border: 1px dotted;
      }
    }
  }
}
